<template>
    <div class="page">
        <van-nav-bar :border="false" :placeholder="true" :fixed="true" :title="$t('我的机器人')" left-arrow
                     @click-left="onClickLeft">
        </van-nav-bar>
        <div class="wrapper" style="padding-top: unset">
            <div style="position: fixed;left:0;top:auto;width: 100%;background-color: #131d26; padding: 10px 15px 0 15px;z-index: 999">
                <div class="filter-bar">
                    <div class="filter-item" @click="active = 0" :class="active == 0?'active':''">
                        <!-- 下拉 -->
                        <van-popover v-model="showPopover" placement="bottom-start" trigger="click">
                            <div class="filter-dropdown">
                                <van-radio-group v-model="status" @change="dItmeChange">
                                    <van-cell-group>
                                        <van-cell :title="$t('全部')" clickable @click="status = '-1'">
                                            <template #right-icon>
                                                <van-radio name="-1" checked-color="#4ec0e5"/>
                                            </template>
                                        </van-cell>
                                        <van-cell :title="$t('未激活')" clickable @click="status = '0'">
                                            <template #right-icon>
                                                <van-radio name="0" checked-color="#4ec0e5"/>
                                            </template>
                                        </van-cell>
                                        <van-cell :title="$t('已激活')" clickable @click="status = '1'">
                                            <template #right-icon>
                                                <van-radio name="1" checked-color="#4ec0e5"/>
                                            </template>
                                        </van-cell>
                                        <van-cell :title="$t('暂停中')" clickable @click="status = '2'">
                                            <template #right-icon>
                                                <van-radio name="2" checked-color="#4ec0e5"/>
                                            </template>
                                        </van-cell>
                                        <van-cell :title="$t('已过期')" clickable @click="status = '3'">
                                            <template #right-icon>
                                                <van-radio name="3" checked-color="#4ec0e5"/>
                                            </template>
                                        </van-cell>
                                    </van-cell-group>
                                </van-radio-group>
                            </div>
                            <template #reference>
                                <div class="filter-button">
                                    <span>{{$t('我的机器人')}}</span><span class="iconfont icon-tree-node"></span>
                                </div>
                            </template>
                        </van-popover>
                    </div>
                    <div class="filter-item" @click="active = 1" :class="active == 1?'active':''">{{$t('已售')}}</div>
                </div>
            </div>
            <div style="padding-top: 80px" v-if="active == 0">
                <template v-for="(item, index) in list">
                    <div class="page-robot-list" :key="index" style="margin-bottom: 10px;">
                        <div class="robot-card">
                            <div class="robot-card_hd flex-center">
                                <img class="ro-logo" src="../../assets/pictures/logo.png">
                                <div class="tt">{{$t(item.robot.name)}}</div>
                                <template v-if="item.robot.type == 0">
                                    <div class="status" :class="item.status == '3' || item.status == '5' ?'text-yellow':''">
                                        <span class="status-blue" v-if="item.status == 1 && item.r_count > 0"> {{$t('工作中')}}</span>
                                        <span class="status-green" v-else-if="item.status == 1 && item.r_count == 0">{{$t('连线中')}}</span>
                                        <span v-else-if="item.status == 5">{{$t('出售待确认中')}}</span>
                                        <span class="status-black" v-else-if="item.status == 3">{{$t('已过期')}}</span>
                                        <span v-else>{{$t('未激活')}}</span>
                                    </div>
                                </template>
                                <template v-else>
                                    <div class="status">
                                        <span class="status-blue" v-if="item.status == 1"> {{$t('工作中')}}</span>
                                        <span class="status-green" v-else-if="item.status == 6">{{$t('赎回申请中')}}</span>
                                        <span class="status-black" v-else-if="item.status == 3">{{$t('已过期')}}</span>
                                        <span class="status-black" v-else-if="item.status == 7">{{$t('已赎回')}}</span>
                                        <span v-else>{{$t('未激活')}}</span>
                                    </div>
                                </template>
                            </div>
                            <div class="rbody">
                                <template v-if="item.robot.type == 0">
                                    <div class="robot-items ">
                                        <div class="item" v-if="item.robot.level != 0 && item.status == 1">
                                            <span class="t">{{$t('冻结本金')}}: </span>
                                            <span class="text-blue">{{parseFloat(item.min_money).toFixed(2)}} {{$t('USDT')}}</span>
                                        </div>
                                        <div class="item" v-if="item.robot.level == 0" style="color: yellowgreen">
                                            <span class="t">{{$t('冻结本金')}}: </span>
                                            <span class="text-blue">{{parseFloat(item.min_money).toFixed(2)}} {{$t('USDT')}}</span>
                                        </div>
                                        <div class="item">
                                            <span class="t">{{$t('来源')}}: </span>
                                            <span class="text-blue">
                                            <template v-if="item.source == 0">{{$t('签约购买')}}</template>
                                            <template v-else-if="item.source == 1">{{$t('出售/赠送')}}</template>
                                            <template v-else-if="item.source == 2">{{$t('系统赠送')}}</template>
                                            <template v-else-if="item.source == 3">{{$t('邀请奖励赠送')}}</template>
                                            <template v-else-if="item.source == 5">{{$t('签到赠送')}}</template>
                                        </span>
                                        </div>
                                        <div class="item"><span class="t">
                                            {{$t('价格')}}：</span>
                                            <span class="text-blue">{{item.price}} {{$t('USDT')}}</span>
                                        </div>
                                        <div class="item">
                                            <span class="t">{{$t('有效期')}}：</span>
                                            <span class="text-blue">{{item.robot.days}}</span>
                                            {{$t('天')}}
                                        </div>
                                        <div class="item">
                                            <span class="t">{{$t('做单价格')}}：</span>
                                            <span class="text-blue">{{parseFloat(item.min_money).toFixed(2)}} {{$t('USDT')}}</span>
                                            
                                        </div>
                                        <div class="item">
                                            <span class="t">{{$t('获得时间')}}：</span>
                                            {{item.created_at}}
                                        </div>
                                        <div class="item" v-if="item.last_actived_at">
                                            <span class="t">{{$t('最后激活时间')}}：</span> {{item.last_actived_at}}
                                        </div>
                                        <div class="item" v-if="item.first_actived_at && item.use_time">
                                            <span class="t">{{$t('已运行时间')}}：</span>
                                            <span class="text-blue">{{Math.floor(item.use_time / 86400)}}</span>
                                            {{$t('天')}}
                                            <span class="text-blue">{{Math.floor(item.use_time % 86400 / 3600)}}</span>
                                            {{$t('小时')}}
                                            <span class="text-blue">{{Math.floor(item.use_time % 86400 % 3600 / 60)}}</span>
                                            {{$t('分')}}

                                        </div>
                                        <div class="item" v-if="item.form_user"><span
                                                class="t">{{$t('出售/赠送人')}}：</span> {{item.form_user}}
                                        </div>
                                        <div class="item"><span class="t">{{$t('唯一码')}}：</span>
                                            <span class="text-blue">{{item.password}}</span>
                                        </div>
                                    </div>
                                    <van-button type="info" round size="small"
                                                v-if="item.status == 0 && item.robot.level != 0"
                                                @click="sellShow(item)">{{$t('出售/赠送')}}
                                    </van-button>
                                    <van-button type="primary"  size="small"
                                                v-if="item.status == 0 || item.status == 2"
                                                @click="onActive(item)">{{$t('启动')}}
                                    </van-button>
                                    <van-button type="warning"  size="small"
                                                v-if="item.status == 1"
                                                @click="showStop(item)">
                                        {{$t('暂停')}}
                                    </van-button>
                                   <van-button type="danger"  size="small"
                                               v-if="item.status == 5"
                                               @click="onSellRet(item)">
                                       {{$t('回撤出售申请')}}
                                   </van-button>
                                </template>

                                <template v-else>
                                    <div class="robot-items ">
                                        <div class="item">
                                            <span class="t">{{$t('运营资金')}}: </span>
                                            <span class="text-blue">{{parseFloat(parseFloat(parseFloat(item.min_money) + parseFloat(item.profit)).toFixed(6))}}</span>
                                            <span style="font-size: 12px"> {{$t('USDT')}}</span>
                                        </div>
                                        <div class="item">
                                            <span class="t">{{$t('当前收益')}}: </span>
                                            <span class="text-blue">{{parseFloat(item.profit)}}</span>
                                            <span style="font-size: 12px"> {{$t('USDT')}}</span>
                                        </div>
                                        <div class="item">
                                            <span class="t">{{$t('累计收益')}}: </span>
                                            <span class="text-blue">{{parseFloat(item.profit_total)}}</span>
                                            <span style="font-size: 12px"> {{$t('USDT')}}</span>
                                        </div>
                                        <div class="item">
                                            <span class="t">{{$t('周期')}}：</span>
                                            <span class="text-blue">{{item.robot.days}}</span>
                                            {{$t('天')}}
                                        </div>
                                        <div class="item">
                                            <span class="t">{{$t('开始时间')}}：</span>
                                            {{item.created_at}}
                                        </div>
                                        <div class="item" v-if="item.first_actived_at && item.use_time">
                                            <span class="t">{{$t('已运行时间')}}：</span>
                                            <span class="text-blue">{{Math.floor(item.use_time / 86400)}}</span>
                                            {{$t('天')}}
                                            <span class="text-blue">{{Math.floor(item.use_time % 86400 / 3600)}}</span>
                                            {{$t('小时')}}
                                            <span class="text-blue">{{Math.floor(item.use_time % 86400 % 3600 / 60)}}</span>
                                            {{$t('分')}}
                                        </div>
                                        <div class="item"><span class="t">{{$t('唯一码')}}：</span>
                                            <span class="text-blue">{{item.password}}</span>
                                        </div>
                                        <div class="item"><span class="t">{{$t('模式')}}：</span>
                                            <span class="text-blue">{{item.robot.is_stop == 1 ? $t('不可赎回') : $t('可赎回')}}</span>
                                        </div>
                                    </div>
                                    <van-button type="warning"  size="small"
                                                v-if="item.status == 1 && item.robot.is_stop == 0"
                                                @click="showStop(item)">
                                        {{$t('申请赎回')}}
                                    </van-button>
                                </template>
                            </div>
                        </div>
                    </div>
                </template>
            </div>

            <div style="padding-top: 100px" v-if="active == 1">
                <template v-for="(item, index) in list">
                    <div class="page-robot-list" :key="index" style="margin-bottom: 10px;">
                        <div class="robot-card">
                            <div class="ro-heading flex-center">
                                <img class="ro-logo" src="../../assets/pictures/robot-sm.png">
                                <span class="tt">{{$t(item.robot.name)}}</span>
                            </div>
                            <div class="rbody">
                                <div class="robot-items">
                                    <div class="item"><span class="t">{{$t('价格')}}：</span>
                                        {{item.robot.price}}
                                        <span style="font-size: 12px">{{$t('USDT')}}</span></div>
                                    <div class="item"><span class="t">{{$t('购买时间')}}：</span>{{item.created_at}}
                                    </div>
                                    <div class="item"><span class="t">{{$t('出售/赠送时间')}}：</span>{{item.updated_at}}
                                    </div>
                                    <div class="item"><span class="t">{{$t('出售/赠送价格')}}：</span> {{item.sell_price}}
                                        <span
                                                style="font-size: 12px">{{$t('USDT')}}</span>
                                    </div>
                                    <div class="item"><span class="t">{{$t('唯一码')}}：</span>{{item.password}}
                                    </div>
                                    <div class="item"><span class="t">{{$t('对方账户名称')}}：</span>{{item.form_user}}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </template>
            </div>
        </div>
        <van-popup class="bs-popup"
                   v-model="show" position="bottom" closeable>
            <div class="bs-popup-dialog" v-if="sellInfo.robot">
                <div class="bs-popup-head">
                    <div class="flex-center">
                        <img class="ro-logo" src="../../assets/pictures/robot-sm.png">
                        <div class="tt">{{$t(sellInfo.robot.name)}}</div>
                    </div>
                </div>
                <div class="dialog-v-body">
                    <div class="dv-card">
                        <div class="dv-row">
                            <div class="dv-data">
                                <div class="label">{{$t('唯一码')}}</div>
                                <div class="val">{{sellInfo.password}}</div>
                            </div>
                        </div>
                        <div class="dv-row">
                            <div class="dv-data">
                                <div class="label">{{$t('官方价格')}}</div>
                                <div class="val">{{sellInfo.robot.price}}</div>
                            </div>
                            <div class="dv-data" style="width: 50%">
                                <div class="label">{{$t('出售价值')}}</div>
                                <div class="val">{{money}}</div>
                            </div>
                        </div>
                        <div class="dv-row">
                            <div class="dv-data" style="width: 100%;text-align: center">
                                <div style="display: flex;justify-content: space-between;align-items: center;align-content: center;">
                                    <van-icon name="minus" size="14" @click="minus"/>
                                    <div style="width: 100%;padding: 0 8px">
                                        <van-slider
                                                class="s-slider"
                                                v-model="money" min="0" :max="sellInfo.robot.price" step="1"
                                        >
                                            <template #button>
                                                <div class="s-btn">
                                                    {{ money }}
                                                </div>
                                            </template>
                                        </van-slider>
                                    </div>
                                    <van-icon name="plus" size="14" @click="plus"/>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="dv-card">
                        <div class="dv-input-row">
                            <van-field v-model="username" :placeholder="$t('请输入对方手机号码')"/>
                        </div>
                        <div class="dv-less">
                            <div class="label text-gray">
                                {{$t('转入财产请仔细审核信息，如操作失误，无法通过系统或平台客服找回。')}}
                            </div>
                        </div>
                        <div class="dv-foot">
                            <van-button @click="onSell" type="primary">{{$t('确认')}}</van-button>
                        </div>
                    </div>
                </div>
            </div>
        </van-popup>

        <van-popup class="bank-popup" v-model="show_stop" position="bottom">
            <div style="padding: 10px 20px 30px 20px;line-height: 20px;color: #000;font-size: 12px !important;">
                <div class="tips" style="font-weight: bold;color: #999999;padding-bottom: 10px">{{$t('温馨提示')}}</div>
                <div class="tips">{{$t('您当前运营资金')}}:
                    <span style="color: #0184fa;font-weight: bold">{{parseFloat(parseFloat(stop_item.min_money) + parseFloat(stop_item.profit))}}</span>
                    <span> {{$t('USDT')}}</span>
                </div>
                <div class="tips">{{$t('预估日收益可达')}}:
                    <span style="color: #0184fa;font-weight: bold"> {{stop_item.r}}</span>
                    <span> {{$t('USDT')}}</span>
                </div>
                <div class="tips">{{$t('手续费')}}:
                    <span style="color: #0184fa;font-weight: bold" v-if="stop_item.robot">{{parseFloat(stop_item.robot.fee * 100).toFixed(2)}}%</span>
                </div>
                <div class="tips" style="padding-top: 10px">{{$t('申请赎回将无法获得收益，并扣除相应本金作为手续费，确认要申请赎回吗？')}}</div>
            </div>
            <div style="padding: 20px;display: flex;justify-content: space-between">
                <div style="width: 49%">
                    <van-button class="btn" color="#ccc" block @click="onStop2(stop_item)" :disabled="btn_flag">
                        {{$t('申请赎回')}}
                    </van-button>
                </div>
                <div style="width: 49%">
                    <van-button class="btn" color="#0184fa" block @click="show_stop = false">{{$t('取消')}}</van-button>
                </div>
            </div>
        </van-popup>
    </div>
</template>

<script>
    export default {
        components: {},
        data() {
            return {
                btn_flag: false,
                fee: 0,
                money: 0,
                username: '',
                show: false,
                show_stop: false,
                showPopover: false,
                stop_item: {},
                active: '',
                status: '-1',
                list: [],
                sellInfo: {},
                options: [],
            }
        },
        methods: {
            minus() {
                if (this.money >= 1) {
                    this.money = this.money - 1;
                }
            },
            plus() {
                if (this.money + 1 <= this.sellInfo.robot.price) {
                    this.money = this.money + 1;
                }
            },
            setClass(value) {
                let obj = {face: true}
                obj["ro-style-" + `${value % 4}`] = true
                return obj
            },
            onClickLeft() {
                this.$router.go(-1)
            },
            onTabChange(val) {
                if (val == 0) {
                    this.status = '-1';
                } else {
                    this.status = '4';
                }
                this.getData();
            },
            sellShow(item) {
                this.username = '';
                this.sellInfo = item;
                this.money = parseInt(item.robot.price / 2);
                this.show = true;
            },
            getData() {
                this.list = [];
                let params = '';
                if (this.status >= 0) {
                    params = '?status=' + this.status;
                }
                this.$axios.get('/robot/my_robots' + params)
                    .then((res) => {
                        this.list = res.data.data;
                    });
            },
            onSell() {
                if (this.username == '') {
                    this.$toast.success(this.$t('请填写对方账户名称'));
                }
                this.$axios.post('/robot/sell_robot', {
                    id: this.sellInfo.id,
                    username: this.username,
                    money: this.money
                }).then((res) => {
                    if (res.data.code == 0) {
                        this.show = false;
                        this.$toast.success(res.data.msg);
                        this.getData();
                    } else {
                        this.$toast.fail(res.data.msg);
                    }
                }).catch((res) => {
                    this.$toast.fail(res.data.msg);
                });
            },
            onSellRet(item) {
                this.$axios.post('/robot/sell_ret', {id: item.id})
                    .then((res) => {
                        this.$toast.success(res.data.msg);
                        this.getData();
                    });
            },
            onActive(item) {
                this.$axios.post('/robot/activate', {id: item.id})
                    .then((res) => {
                        this.$toast.success(res.data.msg);
                        this.getData();
                    });
            },
            showStop(item) {
                if (item.robot.type == 1) {
                    this.stop_item = item;
                    this.show_stop = true;
                } else {
                    this.onStop(item);
                }
            },
            onStop(item) {
                this.btn_flag = true;
                this.$axios.post('/robot/stop', {id: item.id})
                    .then((res) => {
                        this.$toast.success(res.data.msg);
                        this.getData();
                        this.show_stop = false;
                        this.btn_flag = false;
                    });
            },
            onStop2(item) {
                this.btn_flag = true;
                this.$axios.post('/robot/stop2', {id: item.id})
                    .then((res) => {
                        this.$toast.success(res.data.msg);
                        this.getData();
                        this.show_stop = false;
                        this.btn_flag = false;
                    });
            },
            dItmeChange(value) {
                this.status = value;
                this.showPopover = false
                this.getData();
            }
        },
        mounted() {
            this.getData();
        },
        created() {
            if (this.$route.query.status != undefined) {
                this.status = this.$route.query.status;
            }
            this.options = [
                {text: this.$t('全部'), value: -1},
                {text: this.$t('未激活'), value: 0},
                {text: this.$t('已激活'), value: 1},
                {text: this.$t('暂停中'), value: 2},
                {text: this.$t('已过期'), value: 3},
            ];
        },
        watch: {
            active(nv, ov) {
                if (nv != ov) {
                    this.onTabChange(nv);
                }
            }
        }
    }
</script>
<style>
    .s-slider {
        height: 15px;
        background-color: #ebedf0;
    }

    .s-slider .van-slider__bar {
        background-color: #1989fa;
    }
    .dv-input-row .van-cell{
        background-color: #2b3640;
        border-radius: 8px;
        padding: 10px;
    }
    .dv-input-row .van-field__control{
        color: #ffffff;
    }
</style>